import { Box, Button, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import InputDate from 'components/InputDate';
import AutocompleteCustom, { PropertyNameOptions, SelectTypeOptions } from 'components/Select';
import SelectUser from 'components/SelectUser';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import Order from 'types/entities/Order';
import User from 'types/entities/User';
import Scope from 'types/entities/Scope';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { MultiSelectAutoComplete } from 'components/index';
import React, { useState } from 'react';

import MultiSelectUser from 'components/MultiSelectUser';

type TnMOrderFormProps = {
  handleChange: (e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  handleDateChange: (name: string, date: Moment | null) => void;
  handleSelectOptions: (e: SelectTypeOptions | null, property: PropertyNameOptions) => void;
  handleSelectClient: (client: User | null | undefined) => void;
  handleSelectRepresentative: (user: User | null | undefined) => void;
  handleSwitchSign: (value: boolean) => void;
  handleClientConnectionSwitch: (value: boolean) => void;
  order: Partial<Order> | undefined;
  creators: User[];
  clients: User[];
  deliveryManagers: User[];
  consultants: User[];
  scopes: Scope[];
  onScopeAdded: (newScope: Scope) => void;
  onScopeArchived: (updatedScope: Scope) => void;
  handleScopesChange: (newSelectedScopes: Scope[]) => void;
  selectedScopes: Scope[];
  onDeliveryManagerArchived: (updatedUser: User) => void;
  onConsultantArchived: (updatedUser: User) => void;
  handleConsultantChange: (newSelectedConsultants: User[], reason?: string) => void;
  handleDeliveryManagerChange: (newSelectedDeliveryManager: User[], reason?: string) => void;
  selectedDeliveryManager: User[];
  selectedConsultants: User[];
  availableScopes?: Scope[];
  availableDeliveryManagers?: User[];
  availableConsultants?: User[];
  availableClients?: User[];
  onScopeDeletionAttempt?: () => void;
  onDMUserDeletionAttempt?: () => void;
  onConsultantUserDeletionAttempt?: () => void;
  variant: 'outlined' | 'filled';
};
const TnMOrderForm = (props: TnMOrderFormProps) => {
  const {
    order,
    handleChange,
    handleDateChange,
    handleSelectOptions,
    handleSelectClient,
    handleClientConnectionSwitch,
    creators,
    handleSelectRepresentative,
    clients,
    deliveryManagers,
    consultants,
    scopes,
    onScopeAdded,
    onScopeArchived,
    handleScopesChange,
    selectedScopes,
    onDeliveryManagerArchived,
    onConsultantArchived,
    handleDeliveryManagerChange,
    handleConsultantChange,
    selectedDeliveryManager,
    selectedConsultants,
    availableScopes,
    availableDeliveryManagers,
    availableConsultants,
    onScopeDeletionAttempt = () => undefined,
    onDMUserDeletionAttempt = () => undefined,
    onConsultantUserDeletionAttempt = () => undefined,
    variant,
  } = props;

  const [t] = useTranslation();
  const appState = useSelector((state) => state.app);

  const userLocale = navigator.language || 'fr-FR';
  const dateFormat = userLocale !== 'en-US' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

  const formatDate = (date: string | number | Date | Moment | null | undefined): string => {
    if (!date) return '';
    // const formattedDate = isEditMode
    //   ? moment.utc(date).format(dateFormat) // For edit mode, keeping UTC formatting
    //   : moment.utc(date).local().format(dateFormat); // For create mode, local time zone
    const formattedDate = moment(date).format('YYYY-MM-DD');
    return formattedDate;
  };

  return (
    <Box sx={{ mb: 5 }}>
      <Typography sx={{ mb: 3, fontWeight: 'bold' }}>{t('Enter_professional_info_tm')}</Typography>
      <Grid container spacing={4}>
        {appState.affairs && (
          <>
            <Grid item xs={12} lg={6}>
              <AutocompleteCustom
                label={
                  <>
                    {t('Engagement_by_name_tm')}{' '}
                    <Typography display="inline" color="error">
                      *
                    </Typography>
                  </>
                }
                options={appState.affairs}
                optionsLabels="name"
                handleSelectOptions={handleSelectOptions}
                selectedOption={order?.affair}
                propertyName="affair"
                variant={variant}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <AutocompleteCustom
                label={
                  <>
                    {t('Engagement_by_otp_code_tm')}{' '}
                    <Typography display="inline" color="error">
                      *
                    </Typography>
                  </>
                }
                options={appState.affairs}
                optionsLabels="otp"
                handleSelectOptions={handleSelectOptions}
                selectedOption={order?.affair}
                propertyName="affair"
                variant={variant}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} lg={6}>
          <InputLabel htmlFor="reference_chrono">{t('Reference_chrono_tm')} </InputLabel>
          <TextField
            id="draft_reference"
            name="draft_reference"
            value={order?.draft_reference ?? ''}
            onChange={handleChange}
            placeholder={t('Reference_chrono_tm')}
            fullWidth
            variant={variant}
          />
        </Grid>
        {clients && (
          <Grid item xs={12} lg={6}>
            <SelectUser
              users={creators}
              id="representative"
              selectedUser={order?.delegue || null}
              onSelectUser={handleSelectRepresentative}
              label={t('Representative_select_tm')}
              variant={variant}
            />
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <InputLabel htmlFor="project_name">
            {t('Project_name_tm')}{' '}
            <Typography display="inline" color="error">
              *
            </Typography>
          </InputLabel>
          <TextField
            id="project_name_tm"
            name="name_spec"
            value={order?.name_spec || ''}
            onChange={handleChange}
            placeholder={t('Project_name_tm')}
            fullWidth
            variant={variant}
            required
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <InputLabel htmlFor="ref_spec">
            {t('Spec_reference_tm')}{' '}
            {/* <Typography display="inline" color="error">
              *
            </Typography> */}
          </InputLabel>
          <TextField
            id="draft_reference"
            name="ref_spec"
            value={order?.ref_spec || ''}
            onChange={handleChange}
            placeholder={t('Spec_reference_tm')}
            fullWidth
            variant={variant}
            required
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <InputDate
            label={
              <>
                {t('Start_date_tm')}{' '}
                <Typography display="inline" color="error">
                  *
                </Typography>
              </>
            }
            name="start_date"
            id="start_date"
            placeholder={dateFormat}
            onchange={handleDateChange}
            value={formatDate(order?.start_date) || ''}
            required
            variant={variant}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <InputDate
            label={
              <>
                {t('End_date_tm')}{' '}
                <Typography display="inline" color="error">
                  *
                </Typography>
              </>
            }
            name="end_date"
            id="end_date"
            placeholder={dateFormat}
            onchange={handleDateChange}
            value={formatDate(order?.end_date) || ''}
            required
            min={order?.start_date}
            variant={variant}
            // showError
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <InputLabel htmlFor="purchase_order">{t('purchase_order')} </InputLabel>
          <TextField
            id="purchase_order"
            name="ref_order"
            value={order?.ref_order || ''}
            onChange={handleChange}
            placeholder={t('purchase_order')}
            fullWidth
            variant={variant}
            required
          />
        </Grid>

        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            p: 1,
            position: 'relative',
            '& .MuiAvatarGroup-root': {
              display: 'flex',
              flexWrap: 'wrap', // Changed from wrap-reverse
              alignItems: 'center',
              justifyContent: 'flex-start', // Changed from flex-end
            },
          }}
        >
          <InputLabel htmlFor="scopes">
            {t('Scopes_tm')}
            <Typography display="inline" color="error">
              *
            </Typography>
          </InputLabel>{' '}
          <MultiSelectAutoComplete
            items={scopes} // Assuming 'scopes' is an array of { label: string, value: string }
            onChange={handleScopesChange}
            value={selectedScopes} // {selectedScopes} // Assuming you have a state 'selectedScopes' for this
            sx={{ width: '49%' }}
            selectAllLabel="Select scopes on the list or create your own"
            variant={variant}
            onScopeAdded={onScopeAdded} // Pass down the callback
            onScopeArchived={onScopeArchived} // Pass down the callback for archiving scopes
            availableScopes={availableScopes}
            onScopeDeletionAttempt={onScopeDeletionAttempt}
            maximumVisible={4}
          />
        </Grid>
      </Grid>
      <Typography sx={{ mb: 3, fontWeight: 'bold' }}>{t('Stake_holders_tm')}</Typography>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            p: 2,
            position: 'relative',
            '& .MuiAvatarGroup-root': {
              display: 'flex',
              flexWrap: 'wrap', // Changed from wrap-reverse
              alignItems: 'center',
              justifyContent: 'flex-start', // Changed from flex-end
            },
          }}
        >
          <InputLabel htmlFor="deliverymangers">
            {t('Delivery_managers_tm')}{' '}
            <Typography display="inline" color="error">
              *
            </Typography>
          </InputLabel>
          <MultiSelectUser
            users={deliveryManagers}
            id="delivery-managers"
            onSelectUsers={handleDeliveryManagerChange}
            selectedUsers={selectedDeliveryManager}
            onUserArchived={onDeliveryManagerArchived}
            label="Select Delivery Managers"
            variant={variant}
            availableUsers={availableDeliveryManagers || []}
            onUserDeletionAttempt={onDMUserDeletionAttempt}
            maximumVisible={3}
          />
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            p: 2,
            position: 'relative',
            '& .MuiAvatarGroup-root': {
              display: 'flex',
              flexWrap: 'wrap', // Changed from wrap-reverse
              alignItems: 'center',
              justifyContent: 'flex-start', // Changed from flex-end
            },
          }}
        >
          <InputLabel htmlFor="consultants">
            {t('Consultants_tm')}
            <Typography display="inline" color="error">
              *
            </Typography>
          </InputLabel>
          <MultiSelectUser
            users={consultants}
            id="consultants"
            onSelectUsers={handleConsultantChange}
            selectedUsers={selectedConsultants}
            onUserArchived={onConsultantArchived}
            label="Select Consultant"
            variant={variant}
            availableUsers={availableConsultants || []}
            onUserDeletionAttempt={onConsultantUserDeletionAttempt}
            maximumVisible={3}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <InputLabel htmlFor="clientName">
            {t('Client_name_tm')}
            <Typography display="inline" color="error">
              *
            </Typography>
          </InputLabel>
          {clients && (
            <Grid item xs={12} lg={6}>
              <SelectUser
                users={clients}
                selectedUser={order?.client || null}
                onSelectUser={handleSelectClient}
                label=""
                variant={variant}
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} lg={6}>
          <Typography color="neutral" sx={{ mb: 1, fontWeight: 500 }}>
            {t('client_connection_platform')}
            <Typography display="inline" color="error">
              *
            </Typography>
          </Typography>
          <Stack direction="row" gap={2}>
            <Button
              sx={{ minWidth: 100 }}
              onClick={() => handleClientConnectionSwitch(true)}
              variant={order && order.client_connection ? 'contained' : 'outlined'}
              startIcon={order && order.client_connection ? <CheckCircleIcon /> : null}
            >
              {t('yes')}
            </Button>
            <Button
              sx={{ minWidth: 100 }}
              onClick={() => handleClientConnectionSwitch(false)}
              variant={order && !order.client_connection ? 'contained' : 'outlined'}
              startIcon={order && !order.client_connection ? <CheckCircleIcon /> : null}
            >
              {t('no')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
export default TnMOrderForm;
