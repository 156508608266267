import { PostBulkOrderWorkunitsWorkunits } from 'api/OrderWorkunitApiService';
import { TenantMonthsWorkload } from 'features/Admin/AdminDashboard/AdminDashboard';
import { base64toBlob } from 'helpers/utils';
import { capitalize, filter } from 'lodash';
import moment from 'moment';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  addWorkunitToOrderAction,
  changeIsWorkunitsLoadedAction,
  changeMonthQuantityOrderWorkunitAction,
  changeMonthQuantityWorkunitAction,
  decreaseCatalogContentsNumberAction,
  decreaseCatalogMonthSliceAction,
  decreaseSelectedContentsNumberAction,
  decreaseSelectedMonthSliceAction,
  deleteSelectedWorkunitAction,
  increaseCatalogContentsNumberAction,
  increaseCatalogMonthSliceAction,
  increaseSelectedContentsNumberAction,
  increaseSelectedMonthSliceAction,
  injectMonthsAction,
  injectQuartersAction,
  injectScopesInOrderAction,
  injectSelectedWorkunitsAction,
  removeSelectedWorkunitEditAction,
  resetAddedWorkunitAction,
  setSelectedWorkunitEditAction,
  updateSearchParamsAction,
  updateSelectedWorkunitAction,
  updateWorkunitAction,
} from 'store/actions/catalogToOrderActions';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { CatalogWorkunit } from 'types/entities/CatalogWorkunit';
import Order from 'types/entities/Order';
import Scope from 'types/entities/Scope';
import { SelectedWorkunit } from 'types/entities/SelectedWorkunit';
import { Month } from 'types/models/Months';
import { generateRandomId, WorkunitMonth } from '../helpersOrderWorkunits';
import { WorkunitComplexityMonths } from '../OrderWorkunitsDataGrids/SummaryDataGrid/SummaryDataGrid';

const useOrderWorkunitsService = () => {
  const { dispatch } = useAppState();
  const history = useHistory();
  const activeCustomer = useSelector((state) => state.app.customer);
  const catalogToOrderState = useSelector((state) => state.catalogToOrder);
  const currentOrder = useSelector((state) => state.catalogToOrder.order);
  const catalogComplexities = useSelector((state) => state.catalogToOrder.summary.catalogComplexities);
  const tenantMonthsWorkload = useSelector((state) => state.catalogToOrder.summary.tenantWorkload);
  const scopes = useSelector((state) => state.catalogToOrder.scopes);

  const orderFiltersParams = useSelector((state) => state.catalogToOrder.orderHandler.searchParams);
  const selectedWorkunits = useSelector((state) => state.catalogToOrder.selectedWorkunits);
  const months = useSelector((state) => state.catalogToOrder.months);
  const quarters = useSelector((state) => state.catalogToOrder.quarters);
  const order = useSelector((state) => state.catalogToOrder.order);
  const generateQuarters = useCallback((): Month[] | undefined => {
    if (!currentOrder?.end_date || !currentOrder?.start_date || !currentOrder?.id) return undefined;
    const maxDate = moment(currentOrder.end_date);
    const minDate = moment(currentOrder.start_date);

    const dateStart = minDate;
    const dateEnd = maxDate;
    const interim = dateStart.clone();
    const timeValues: Month[] = [];

    const interimQuarter = dateStart.clone().startOf('quarter'); // set to the first day of the quarter
    const interQuarterEnd = dateEnd.clone().endOf('quarter'); // set to the last day of the quarter
    const quartersValues: React.SetStateAction<
      { month: string; value: string; year: string; date: string; quarter: number }[]
    > = [];

    while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
      timeValues.push({
        month: capitalize(interim.format('MMM')),
        year: interim.format('YYYY'),
        value: interim.format('MMMM-YYYY'),
        date: interim.endOf('month').format('YYYY-MM-DD'),
        // date: interim.endOf('month').toISOString(),
      });
      interim.add(1, 'month');
    }

    while (interimQuarter.isSameOrBefore(interQuarterEnd)) {
      quartersValues.push({
        month: `Q${interimQuarter.quarter()}`,
        year: interimQuarter.format('YYYY'),
        value: interimQuarter.format('MMMM-YYYY'),
        date: interimQuarter.endOf('quarter').format('YYYY-MM-DD'),
        // date: interimQuarter.endOf('quarter').toISOString(),
        quarter: interimQuarter.quarter(),
      });
      interimQuarter.add(1, 'Q');
    }
    dispatch(injectQuartersAction(quartersValues));
    dispatch(injectMonthsAction(timeValues));
    return timeValues;
  }, [currentOrder, dispatch]);

  const formateRows = useCallback(
    (
      workunitsFetched: CatalogWorkunit[],
      timeValues: {
        month: string;
        year: string;
        value: string;
        date: string;
      }[]
    ) => {
      const rowsToDisplay: CatalogWorkunit[] = [];
      workunitsFetched.forEach((workunit) => {
        rowsToDisplay.push({
          ...workunit,
          quantity: 0,
          scope: null,
          scope_id: null,
          content: '',
          content2: '',
          content3: '',
          content4: '',
          content5: '',
          months: timeValues.map((m) => ({
            date: m.date,
            quantity: 0,
            price: 0,
            fte: 0,
            totalprice: 0,
            dailyrate: 0,
            workload: 0,
          })),
        });
      });
      return rowsToDisplay;
    },
    []
  );

  const getUniqScopesInOrder = useCallback(() => {
    const uniqScope = new Set<SelectedWorkunit['scope_id']>([]);
    selectedWorkunits.forEach((wu) => uniqScope.add(wu.scope_id));
    const presentScope: Scope[] = [];
    scopes.forEach((s) => {
      if (uniqScope.has(s.id)) presentScope.push(s);
    });
    return presentScope;
  }, [scopes, selectedWorkunits]);

  const getDisplayedWorkunits = useCallback(() => {
    if (orderFiltersParams?.scope) {
      return filter(selectedWorkunits, (workunit) => workunit.scope_id === orderFiltersParams?.scope);
    }
    return selectedWorkunits;
  }, [orderFiltersParams?.scope, selectedWorkunits]);

  const getScopesInOrder = useCallback(
    (selectedWorkunits: SelectedWorkunit[]) => {
      const uniqScope = new Set<SelectedWorkunit['scope_id']>([]);
      selectedWorkunits.forEach((wu) => uniqScope.add(wu.scope_id));
      const presentScope: Scope[] = [];
      scopes.forEach((s) => {
        if (uniqScope.has(s.id)) presentScope.push(s);
      });
      dispatch(injectScopesInOrderAction(presentScope));
    },
    [dispatch, scopes]
  );

  const createSelectedWorkunits = useCallback(() => {
    if (!catalogToOrderState.orderWorkunits) return;
    dispatch(addLoadingAction('createSelectedWorkunits'));
    const { orderWorkunits } = catalogToOrderState;
    let retrievedWorkunits: SelectedWorkunit[] = [];
    if (orderWorkunits && orderWorkunits?.length) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < orderWorkunits.length; i++) {
        // eslint-disable-next-line no-continue
        if (orderWorkunits[i].is_archived) continue;
        const alreadyExistingWorkunit = retrievedWorkunits.find(
          (rwu) =>
            rwu.workunit_id === orderWorkunits[i].workunit_id &&
            rwu.scope_id === orderWorkunits[i].scope_id &&
            rwu.content === orderWorkunits[i].content &&
            rwu.content2 === orderWorkunits[i].content2 &&
            rwu.content3 === orderWorkunits[i].content3 &&
            rwu.content4 === orderWorkunits[i].content4 &&
            rwu.content5 === orderWorkunits[i].content5 &&
            (rwu.workunit_complexity_id === orderWorkunits[i].workunit_complexity_id ||
              (!rwu.workunit_complexity_id && !orderWorkunits[i].workunit_complexity_id))
        );
        if (!alreadyExistingWorkunit) {
          retrievedWorkunits.push({
            ...orderWorkunits[i],
            randomId: generateRandomId(),
            name: orderWorkunits[i].workunit_name ?? '',
            reference: orderWorkunits[i].workunit_reference as string,
            isImmutable: false,
            devise: null,
            price: orderWorkunits[i].price ?? 0,
            order_scope_id: orderWorkunits[i].order_scope_id ?? undefined,
            charge: orderWorkunits[i].charge as number,
            scope_id: orderWorkunits[i].scope_id ?? null,
            scope:
              catalogToOrderState.scopes.find((s) => s.id === orderWorkunits[i].scope_id) ||
              catalogToOrderState.scopes.find((s) => s.name === 'General') ||
              null,
            complexity_name: orderWorkunits[i]?.complexity_name ?? '',
            total: 1,
            months: catalogToOrderState.months.map((m) =>
              moment(m.date).endOf('month').format('MM-YYYY').toString() !==
              moment(orderWorkunits[i].delivery_date).endOf('month').format('MM-YYYY').toString()
                ? {
                    quantity: 0,
                    date: m.date,
                    price: 0,
                    dailyrate: 0,
                    fte: 0,
                    totalprice: 0,
                    workload: 0,
                  }
                : {
                    quantity: 1,
                    date: m.date,
                    price: 0,
                    dailyrate: 0,
                    fte: 0,
                    totalprice: 0,
                    workload: 0,
                  }
            ),
            content: orderWorkunits[i].content ?? null,
            content2: orderWorkunits[i].content2 ?? null,
            content3: orderWorkunits[i].content3 ?? null,
            content4: orderWorkunits[i].content4 ?? null,
            content5: orderWorkunits[i].content5 ?? null,
            accept_standard: '',
            business_unit: '',
            devise_id: 0,
            level_standard: '',
            output: '',
            input: '',
            skill: null,
          });
        }
        if (alreadyExistingWorkunit) {
          // eslint-disable-next-line no-return-assign
          retrievedWorkunits = retrievedWorkunits.map((rwu) =>
            rwu.randomId !== alreadyExistingWorkunit.randomId
              ? { ...rwu }
              : {
                  ...rwu,
                  total: rwu.total + 1,
                  months: rwu.months.map((m) => {
                    if (
                      moment(m.date).endOf('month').format('MM-YYYY').toString() ===
                      moment(orderWorkunits[i].delivery_date).endOf('month').format('MM-YYYY').toString()
                    ) {
                      return {
                        ...m,
                        quantity: m.quantity + 1,
                      };
                    }
                    return m;
                  }),
                }
          );
        }
      }
      getScopesInOrder(retrievedWorkunits);
    }

    dispatch(injectSelectedWorkunitsAction(retrievedWorkunits));
    dispatch(changeIsWorkunitsLoadedAction(true));
    dispatch(removeLoadingAction('createSelectedWorkunits'));
  }, [catalogToOrderState, dispatch, getScopesInOrder]);

  const updateSelectedWorkunitsMonthChanged = useCallback(
    (months: Month[]) => {
      if (!selectedWorkunits.length) return;
      const newSelectedWorkunits = selectedWorkunits.map((sworkunit) => ({
        ...sworkunit,
        months: months.map((m) => {
          const findedMonth = sworkunit.months.find(
            (sm) =>
              moment(sm.date).endOf('month').format('MM-YYYY').toString() ===
              moment(m.date).endOf('month').format('MM-YYYY').toString()
          );
          if (findedMonth) {
            return findedMonth;
          }
          return {
            quantity: 0,
            date: m.date,
            price: 0,
            dailyrate: 0,
            fte: 0,
            totalprice: 0,
            workload: 0,
          };
        }),
      }));
      dispatch(injectSelectedWorkunitsAction(newSelectedWorkunits));
    },
    [selectedWorkunits, dispatch]
  );

  const addWorkunitToOrder = (id: CatalogWorkunit['id']) => {
    const { workunits, scopes } = catalogToOrderState;
    const orderWorkunitToTransfer = workunits.find((wu) => wu.id === id);
    if (!orderWorkunitToTransfer?.scope_id) {
      const scopeGeneral = scopes.find((s) => s.name === 'General');
      if (scopeGeneral && orderWorkunitToTransfer) {
        orderWorkunitToTransfer.scope_id = scopeGeneral?.id;
        orderWorkunitToTransfer.scope = scopeGeneral;
      }
    }

    if (orderWorkunitToTransfer) {
      dispatch(addWorkunitToOrderAction(orderWorkunitToTransfer));
      dispatch(resetAddedWorkunitAction(orderWorkunitToTransfer.id));
    }
  };

  const updateWorkunitMonthQuantity = useCallback(
    (workunitId: number, monthDate: string, quantity: number) => {
      dispatch(changeMonthQuantityWorkunitAction(workunitId, monthDate, quantity));
    },
    [dispatch]
  );

  const updateOrderWorkunitMonthQuantity = useCallback(
    (workunitId: number, monthDate: string, quantity: number) => {
      dispatch(changeMonthQuantityOrderWorkunitAction(workunitId, monthDate, quantity));
    },
    [dispatch]
  );

  const updateWorkunit = (data: Partial<CatalogWorkunit>) => {
    dispatch(updateWorkunitAction(data));
  };

  const updateSelectedWorkunit = (data: Partial<SelectedWorkunit>) => {
    dispatch(updateSelectedWorkunitAction(data));
  };

  const increaseCatalogMonthSlice = () => {
    dispatch(increaseCatalogMonthSliceAction());
  };

  const decreaseCatalogMonthSlice = () => {
    dispatch(decreaseCatalogMonthSliceAction());
  };

  const increaseSelectedMonthSlice = () => {
    dispatch(increaseSelectedMonthSliceAction());
  };

  const decreaseSelectedMonthSlice = () => {
    dispatch(decreaseSelectedMonthSliceAction());
  };

  const increaseCatalogContentColumnsNumber = () => {
    dispatch(increaseCatalogContentsNumberAction());
  };

  const decreaseCatalogContentColumnsNumber = () => {
    dispatch(decreaseCatalogContentsNumberAction());
  };

  const increaseOrderContentColumnsNumber = () => {
    dispatch(increaseSelectedContentsNumberAction());
  };

  const decreaseOrderContentColumnsNumber = () => {
    dispatch(decreaseSelectedContentsNumberAction());
  };

  const deleteSelectedWorkunit = (id: number) => {
    dispatch(deleteSelectedWorkunitAction(id));
  };

  const setSelectedWorkunitToEdit = (id: number) => {
    dispatch(setSelectedWorkunitEditAction(id));
  };

  const removeSelectedWorkunitToEdit = (id: number) => {
    dispatch(removeSelectedWorkunitEditAction(id));
  };

  const calculRate = useCallback(
    (
      monthUsed: WorkunitComplexityMonths,
      dailyRateWorkload: TenantMonthsWorkload,
      workunit: SelectedWorkunit,
      month: WorkunitComplexityMonths
    ): number => {
      const totalWorkload =
        numberToFixed(monthUsed.workload) + numberToFixed(workunit.charge) * numberToFixed(month.quantity);
      const totalCost = numberToFixed(monthUsed.price) + numberToFixed(workunit.price) * numberToFixed(month.quantity);
      const rate = totalCost / totalWorkload;

      if (dailyRateWorkload?.is_daily_unit) {
        return numberToFixed(rate);
      }
      return rate * 7.35;
    },
    []
  );

  const computeAlreadyUsedQuarters = useCallback(
    (
      usedQuarters: WorkunitComplexityMonths[],
      workunitMonth: WorkunitComplexityMonths,
      workunit: SelectedWorkunit,
      order: Order | undefined,
      dailyRateWorkload: TenantMonthsWorkload | undefined
    ): WorkunitMonth[] => {
      const isWithinQuarter = (date: string, quarterUsed: WorkunitComplexityMonths): boolean => {
        const quarterStart = moment(quarterUsed.date).startOf('Q');
        const quarterEnd = moment(quarterUsed.date).endOf('Q');
        return moment(date).isBetween(quarterStart, quarterEnd, 'day', '[]');
      };

      const calculateDailyRate = (monthUsed: WorkunitComplexityMonths): number => {
        if (dailyRateWorkload?.is_daily_unit && dailyRateWorkload.is_daily_unit === true) {
          return (
            (monthUsed.price + workunit.price * workunitMonth.quantity) /
            (monthUsed.workload + workunit.charge * workunitMonth.quantity)
          );
        }
        return (
          ((monthUsed.price + workunit.price * workunitMonth.quantity) /
            (monthUsed.workload + workunit.charge * workunitMonth.quantity)) *
          7.35
        );
      };

      return usedQuarters.map((quarterUsed) => {
        if (isWithinQuarter(workunitMonth?.date, quarterUsed)) {
          const newQuarterUsed: WorkunitMonth = {
            ...quarterUsed,
            dailyrate: workunitMonth.quantity > 0 ? calculateDailyRate(quarterUsed) : quarterUsed.dailyrate,
            fte: 0,
            workload: quarterUsed.workload + workunit.charge * workunitMonth.quantity,
            price: quarterUsed.price + workunit.price * workunitMonth.quantity,
            quantity: quarterUsed.quantity + workunitMonth.quantity,
            totalprice: quarterUsed.totalprice,
          };
          if (workunit?.complexity?.id) {
            newQuarterUsed[workunit.complexity.id] =
              quarterUsed[workunit.complexity.id] + workunitMonth.quantity * workunit.charge;
          }
          return newQuarterUsed;
        }
        return quarterUsed;
      });
    },
    []
  );

  const computeAlreadyUsedMonth = useCallback(
    (
      usedMonths: WorkunitComplexityMonths[],
      m: WorkunitComplexityMonths,
      workunit: SelectedWorkunit,
      order: Order | undefined,
      dailyRateWorkload?: TenantMonthsWorkload
    ): WorkunitMonth[] => {
      const test = usedMonths.map((monthUsed) => {
        if (dailyRateWorkload) {
          const startOfMonth = moment(dailyRateWorkload.date).startOf('month');
          const endOfMonth = moment(dailyRateWorkload.date).endOf('month');
          const startOfOrder = moment(order?.start_date);
          const endOfOrder = moment(order?.end_date);
          const monthDate = moment(monthUsed.date);

          if (!monthDate.isBetween(startOfMonth, endOfMonth, 'month', '[]')) {
            return monthUsed;
          }

          let prorata = 1;
          if (startOfOrder.isBetween(startOfMonth, endOfMonth, 'day', '(]')) {
            prorata = endOfMonth.diff(startOfOrder, 'days') / endOfMonth.daysInMonth();
          }

          if (endOfOrder.isBetween(startOfMonth, endOfMonth, 'day', '[)')) {
            const daysInMonth = startOfMonth.daysInMonth();
            const daysUntilEndOfOrder = endOfOrder.diff(startOfMonth, 'days');
            prorata = daysUntilEndOfOrder / daysInMonth;
          }

          return {
            ...monthUsed,
            dailyrate: m.quantity > 0 ? calculRate(monthUsed, dailyRateWorkload, workunit, m) : monthUsed.dailyrate,
            fte: monthUsed.fte + (m.quantity * workunit.charge) / (dailyRateWorkload.workload * prorata || 1),
            workload: monthUsed.workload + workunit.charge * m.quantity,
            price: monthUsed.price + workunit.price * m.quantity,
            quantity: monthUsed.quantity + m.quantity,
            totalPrice: monthUsed.totalprice,
            ...(workunit.complexity?.id &&
              !Number.isNaN(workunit.charge) && {
                [workunit.complexity.id]: monthUsed[workunit.complexity.id] + m.quantity * workunit.charge,
              }),
          };
        }
        return {
          ...monthUsed,
          dailyrate: monthUsed.dailyrate,
          fte: 0,
          workload: monthUsed.workload + workunit.charge * m.quantity,
          price: monthUsed.price + workunit.price * m.quantity,
          quantity: monthUsed.quantity + m.quantity,
          totalPrice: monthUsed.totalprice,
        };
      });
      return test;
    },
    [calculRate]
  );

  const computeSummaryQuarters = useCallback((): WorkunitMonth[] => {
    const displayedWorkunits = getDisplayedWorkunits();
    const complexitiesIds = catalogComplexities.map((c) => c.id as number);
    let objectWithComplexitiesIdsAsProperties = {};
    if (tenantMonthsWorkload.length === 0) {
      return [];
    }
    if (complexitiesIds) {
      complexitiesIds.forEach((cId: number) => {
        objectWithComplexitiesIdsAsProperties = {
          ...objectWithComplexitiesIdsAsProperties,
          [cId]: 0,
        };
      });
    }

    let usedQuarters: WorkunitComplexityMonths[] = quarters.map((m) => ({
      quantity: 0,
      date: m.date,
      price: 0,
      dailyrate: 0,
      fte: 0,
      totalprice: 0,
      workload: 0,
      ...objectWithComplexitiesIdsAsProperties,
    }));

    displayedWorkunits.forEach((workunit) => {
      workunit.months.forEach((workunitMonth) => {
        const dailyRateWorkload = tenantMonthsWorkload?.find((monthWorkload) => {
          const monWorkloadTrim = monthWorkload.date;
          return (
            moment(monWorkloadTrim).format('MM-YYYY').toString() ===
            moment(workunitMonth.date).format('MM-YYYY').toString()
          );
        });
        usedQuarters = computeAlreadyUsedQuarters(
          usedQuarters,
          workunitMonth,
          workunit,
          order ?? undefined,
          dailyRateWorkload
        );
      });
    });
    return usedQuarters;
  }, [catalogComplexities, computeAlreadyUsedQuarters, getDisplayedWorkunits, order, quarters, tenantMonthsWorkload]);

  const computeSummaryMonths = useCallback(() => {
    const displayedWorkunits = getDisplayedWorkunits();
    const { startSlice, endSlice } = catalogToOrderState.summaryHandler.monthSlice;
    displayedWorkunits.map((workunit) => ({
      ...workunit,
      months: workunit.months.filter((m, index) => index >= startSlice && index <= endSlice),
    }));
    const complexitiesIds = catalogComplexities.map((c) => c.id as number);
    const objectWithComplexitiesIdsAsProperties = complexitiesIds.reduce((acc, curr) => ({ ...acc, [curr]: 0 }), {});
    const monthsDisplayed = months.filter((m, index) => index >= startSlice && index <= endSlice);
    let usedMonths = monthsDisplayed.map((m) => ({
      quantity: 0,
      date: m.date,
      price: 0,
      dailyrate: 0,
      fte: 0,
      totalprice: 0,
      workload: 0,
      ...objectWithComplexitiesIdsAsProperties,
    }));

    if (tenantMonthsWorkload.length === 0) {
      return usedMonths;
    }

    for (let i = 0; i < displayedWorkunits.length; i += 1) {
      const workunit = displayedWorkunits[i];
      const { months } = workunit;

      for (let j = 0; j < months.length; j += 1) {
        const workunitMonth = months[j];
        const { date } = workunitMonth;

        const filteredWorkload = tenantMonthsWorkload
          ?.filter((monthWorkload: TenantMonthsWorkload) => {
            const monthDate = moment(monthWorkload.date, 'YYYY-MM-DD');
            return monthDate.format('MM-YYYY') === moment(date).format('MM-YYYY');
          })
          .sort((a, b) => new Date(a.updated_at).getDate() - new Date(b.updated_at).getDate())[0];

        usedMonths = computeAlreadyUsedMonth(usedMonths, workunitMonth, workunit, order ?? undefined, filteredWorkload);
      }
    }
    return usedMonths;
  }, [
    catalogComplexities,
    catalogToOrderState.summaryHandler.monthSlice,
    computeAlreadyUsedMonth,
    getDisplayedWorkunits,
    months,
    order,
    tenantMonthsWorkload,
  ]);

  const numberToFixed = (number: number) => (number ? parseFloat(number.toFixed(2)) : 0);

  const redirectToPreview = (orderIsSubmitted: boolean) => {
    if (orderIsSubmitted) {
      history.push(`/${activeCustomer?.slug}/orders/quotes?status=pending`);
    }
    if (!orderIsSubmitted) {
      history.push(`/${activeCustomer?.slug}/orders/quotes?status=drafted`);
    }
  };
  const createBodyPostBulkOrderWorkunits = () => {
    const orderWorkunits: PostBulkOrderWorkunitsWorkunits = [];
    selectedWorkunits.forEach((workunit) => {
      workunit.months.forEach((m) => {
        if (m.quantity > 0) {
          // const dateOnly = moment.utc(m.date).format('YYYY-MM-DD'); // Converts to date-only string
          // const utcDate = moment.utc(dateOnly);
          // console.log(`dateOnly: ${dateOnly}`);
          // console.log(`Processed UTC Date: ${utcDate.format('YYYY-MM-DD')}`);
          const dateOnly = moment(m.date).format('YYYY-MM-DD');
          orderWorkunits.push({
            workunit_id: (workunit?.workunit?.id || workunit.workunit_id) as number,
            workunit_name: workunit.name,
            workunit_reference: workunit.reference,
            delivery_date: dateOnly,
            scope_id: workunit.scope_id ?? workunit?.scope?.id ?? null,
            qte: m.quantity,
            content: workunit.content ? workunit.content : ' ',
            content2: workunit.content2 ? workunit.content2 : ' ',
            content3: workunit.content3 ? workunit.content3 : ' ',
            content4: workunit.content4 ? workunit.content4 : ' ',
            content5: workunit.content5 ? workunit.content5 : ' ',
            workunit_complexity_id: (workunit?.complexity?.id as number) || null,
          });
        }
      });
    });
    return orderWorkunits;
  };

  const downloadExcel = (excel: { filename: any; data: string }) => {
    const outputFilename = `${excel.filename}.xlsx`;
    const blob = base64toBlob(excel.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  };

  const updateSearchFilters = (newSearchValue: { [key: string]: string | number }, target: 'catalog' | 'selected') => {
    dispatch(updateSearchParamsAction(newSearchValue, target));
  };

  return {
    createSelectedWorkunits,
    updateSelectedWorkunitsMonthChanged,
    addWorkunitToOrder,
    updateWorkunitMonthQuantity,
    updateOrderWorkunitMonthQuantity,
    updateWorkunit,
    updateSelectedWorkunit,
    increaseCatalogMonthSlice,
    decreaseCatalogMonthSlice,
    increaseSelectedMonthSlice,
    decreaseSelectedMonthSlice,
    increaseCatalogContentColumnsNumber,
    decreaseCatalogContentColumnsNumber,
    increaseOrderContentColumnsNumber,
    decreaseOrderContentColumnsNumber,
    generateMonthsQuarters: generateQuarters,
    formateRows,
    computeSummaryMonths,
    computeSummaryQuarters,
    redirectToPreview,
    createBodyPostBulkOrderWorkunits,
    downloadExcel,
    deleteSelectedWorkunit,
    setSelectedWorkunitToEdit,
    removeSelectedWorkunitToEdit,
    updateSearchFilters,
    getScopesInOrder,
    getDisplayedWorkunits,
    getUniqScopesInOrder,
  };
};

export default useOrderWorkunitsService;
