import { ExpandMore } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionSummary,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import useDeliverableRights from 'hooks/rights/useDeliverableRights';
import React, { useCallback, useEffect, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDrawerAction, openDrawerAction } from 'store/actions/drawerActions';
import { openDialogAction } from 'store/actions/dialogsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
// import './DeliverableActions.scss';
import { setSnackbarAction } from 'store/actions/snackbarActions';

type QuickActionWu = {
  workunit_name?: string;
  workunit_reference?: string;
  forecast_date?: string;
  content?: string;
  content2?: string;
};
type QuickActions = {
  cancellation: boolean;
  cancelsubmission: boolean;
  cancelvalidation: boolean;
  submission: boolean;
  validation: boolean;
  acceptation: boolean;
  delete: boolean;
  eval?: 'A' | 'B' | 'C';
};
type QuickActionsAffectations = {
  wu_parameters?: number;
  custom_wu_parameters?: number;
  ordered_wu_parameters?: number;
  cancellation: number;
  cancelsubmission: number;
  cancelvalidation: number;
  submission: number;
  validation: number;
  acceptation: number;
  delete: number;
  eval?: number;
};
type QuickActionStatusDisabled = {
  wu_parameters?: boolean;
  custom_wu_parameters?: boolean;
  ordered_wu_parameters?: boolean;
  cancellation: boolean;
  cancelsubmission: boolean;
  cancelvalidation: boolean;
  submission: boolean;
  validation: boolean;
  acceptation: boolean;
  delete: boolean;
  eval?: boolean;
};

const DeliverableActions: React.FC = () => {
  const [t] = useTranslation();

  const { dispatch } = useAppState();
  const {
    getDeliverableByListId,
    deleteDeliverables,
    cancelDeliverables,
    changeMissionAdvancement,
    setRatingDeliverables,
  } = useDeliverableService();
  const loadings = useSelector((state) => state.loadings);
  const appState = useSelector((state) => state.app);
  const state = useSelector((state) => state.deliverable);
  const clientConnection = useSelector((state) => state.deliverable.order?.client_connection);
  const [selectedDeliverables, selectSelectedDeliverables] = useState<OrderWorkunit[]>([]);
  const { canValidate, canSubmit, canCancel, canManagerRate, canManagerAccept } =
    useDeliverableRights(selectedDeliverables);
  const [quickActions, setQuickActions] = useState<QuickActions>({
    cancellation: false,
    cancelsubmission: false,
    cancelvalidation: false,
    submission: false,
    validation: false,
    acceptation: false,
    delete: false,
  });
  const [quickActionsAffectation, setQuickActionsAffectation] = useState<QuickActionsAffectations>({
    cancellation: 0,
    cancelsubmission: 0,
    cancelvalidation: 0,
    submission: 0,
    validation: 0,
    acceptation: 0,
    delete: 0,
    eval: 0,
  });
  const [quickActionStatusDisabled, setQuickActionStatusDisabled] = useState<QuickActionStatusDisabled>({
    cancellation: true,
    cancelsubmission: true,
    cancelvalidation: true,
    submission: true,
    validation: true,
    acceptation: true,
    delete: true,
    eval: false,
  });
  const [cancelReason, setCancelReason] = useState<string | null>(null);
  const cancellationLabel = { inputProps: { 'aria-label': t('Cancel_selected_deliverables') } };
  const cancellationSubmissionLabel = { inputProps: { 'aria-label': t('Cancel_submission_selected_deliverables') } };
  const cancellationValidationLabel = { inputProps: { 'aria-label': t('Cancel_validation_selected_deliverables') } };
  const userIsAdmin = appState.roles?.includes('admin');
  const userIsConsultant = appState.roles?.toString().includes('consultant');
  const userIsBm = appState.roles?.toString().includes('bm');
  const userIsTl = appState.roles?.toString().includes('team_leader');
  const userIsTlPlus = appState.roles?.toString().includes('team_leader_plus');
  const userIsCustomer = appState.user?.is_client; // TODO use customer roles ?
  const canDeliverablesBeEvaluated = selectedDeliverables.every(
    (mission) =>
      mission.mission_advancement_id &&
      mission.mission_advancement_id >= MissionAdvancementPossibilities.RESERVE_CLIENT &&
      !mission.rating
  );

  const getSelectedDeliverableByListId = useCallback(async () => {
    const deliverables = await getDeliverableByListId(
      state?.order?.id as number,
      state.selectedDeliverablesModel as number[]
    );
    selectSelectedDeliverables(deliverables);
  }, [getDeliverableByListId, state?.order?.id, state.selectedDeliverablesModel]);

  useEffect(() => {
    getSelectedDeliverableByListId();
  }, [getSelectedDeliverableByListId, state.selectedDeliverablesModel, state?.order?.id]);

  useEffect(() => {
    setQuickActionStatusDisabled({
      cancellation: canCancel,
      cancelsubmission: canCancelSubmission(),
      cancelvalidation: canCancelValidation(),
      submission: canSubmit,
      validation: canValidate,
      acceptation: isAcceptDisabled(),
      delete: isDeleteDeliverableDisable(),
      eval: canUserEvaluateDeliverables(),
    });
    if (!state.selectedAllDeliverables) {
      setQuickActionsAffectation({
        ...quickActionsAffectation,
        cancellation: countCancellableMissions(),
        submission: countSubmittableDeliverable(),
        validation: countValidableDeliverables(),
        cancelsubmission: countCancellableSubmittedDeliverables(),
        cancelvalidation: countCancellableValidatedDeliverables(),
        delete: countDeletableDeliverable(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeliverables, quickActions]);

  const countCancellableMissions = () => {
    let count = 0;
    if (userIsAdmin || userIsBm || userIsTl || userIsTlPlus) {
      count = selectedDeliverables.filter(
        (mission) =>
          !mission.mission_advancement_id || mission.mission_advancement_id < MissionAdvancementPossibilities.RESERVE_TL
      ).length;
    }
    if (userIsConsultant) {
      count = selectedDeliverables.filter((mission) => !mission.mission_advancement_id).length;
    }
    return count;
  };

  // Check if any deliverable has either consultant.id or client.id undefined
  function anyDeliverableHasUndefinedId(deliverables: OrderWorkunit[]) {
    return deliverables.some((deliverable) => !deliverable.consultant?.id || !deliverable.client?.id);
  }

  // Check if any deliverable meets the acceptance status criteria
  function anyDeliverableAboveAcceptanceStatus(deliverables: OrderWorkunit[]) {
    return deliverables.some(
      (deliverable) =>
        deliverable.mission_advancement_id &&
        deliverable.mission_advancement_id >= MissionAdvancementPossibilities.ACCEPTATION_TL &&
        (!deliverable.consultant?.id || !deliverable.client?.id)
    );
  }

  const submit = async (event: MouseEvent) => {
    if (state.selectedAllDeliverables) {
      return;
    }
    let isClientDeliverableAboveAcceptanceStatus = false;
    if (userIsCustomer) {
      isClientDeliverableAboveAcceptanceStatus = anyDeliverableAboveAcceptanceStatus(selectedDeliverables);

      if (!isClientDeliverableAboveAcceptanceStatus) {
        isClientDeliverableAboveAcceptanceStatus = true;
      }
    }
    const anyIsUndefinedId = anyDeliverableHasUndefinedId(selectedDeliverables);
    if (
      !quickActions?.cancellation &&
      anyIsUndefinedId &&
      !quickActions?.cancelsubmission &&
      !quickActions.cancelvalidation &&
      !isClientDeliverableAboveAcceptanceStatus
    ) {
      dispatch(
        setSnackbarAction({
          message:
            'The selected deliverable must have a consultant and client assigned before submitting. Please add the missing assignments.',
          open: true,
          severity: 'info',
        })
      );
      return;
    }
    const orderWorkunitsIds = state.selectedDeliverablesModel as OrderWorkunit['id'][];
    if (quickActions.submission) {
      await changeMissionAdvancement(
        orderWorkunitsIds,
        MissionAdvancementPossibilities.SUBMITTED,
        true,
        state.selectedAllDeliverables
      );
    }
    if (quickActions.delete) {
      await deleteDeliverables(orderWorkunitsIds, state.selectedAllDeliverables);
    }
    if (quickActions.validation) {
      await changeMissionAdvancement(
        orderWorkunitsIds,
        MissionAdvancementPossibilities.ACCEPTATION_TL,
        true,
        state.selectedAllDeliverables
      );
    }

    if (quickActions.acceptation) {
      await changeMissionAdvancement(
        orderWorkunitsIds,
        MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
        true,
        state.selectedAllDeliverables
      );
    }
    if (quickActions.eval) {
      if (quickActions.acceptation) {
        const deliverablesRatable = selectedDeliverables.map((d) => ({
          ...d,
          mission_advancement_id: MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
        }));
        await setRatingDeliverables(deliverablesRatable, quickActions.eval, null, state.selectedAllDeliverables);
      } else {
        await setRatingDeliverables(selectedDeliverables, quickActions.eval, null, state.selectedAllDeliverables);
      }
    }

    if (
      (quickActions.cancelsubmission && quickActions.cancelvalidation && !quickActions?.cancellation) ||
      (quickActions.cancelsubmission && countCancellableValidatedDeliverables() > 0)
    ) {
      event.preventDefault();
      const confirmOk = async () => {
        await changeMissionAdvancement(orderWorkunitsIds, null);
        dispatch(closeDrawerAction());
      };
      dispatch(
        openDialogAction({
          name: 'deliverableSubmissionCancellation',
          data: { confirm: confirmOk },
        })
      );
    } else if (quickActions.cancelsubmission) {
      await changeMissionAdvancement(orderWorkunitsIds, null);
    } else if (quickActions.cancelvalidation) {
      if (countCancellableSubmittedDeliverables() > 0 || countCancellableMissions() > 0) {
        dispatch(
          setSnackbarAction({
            message:
              'Cancel validation could not be done as there are other selected submitted/cancelled deliverables.',
            open: true,
            severity: 'info',
          })
        );
        return;
      }
      await changeMissionAdvancement(orderWorkunitsIds, MissionAdvancementPossibilities.SUBMITTED);
    }

    if (quickActions?.cancellation && cancelReason) {
      const orderWorkunitsIds = selectedDeliverables.map((orderWorkunit) => orderWorkunit.id);
      await cancelDeliverables(orderWorkunitsIds, cancelReason, state.selectedAllDeliverables);
    }
    if (!quickActions.cancelsubmission && !quickActions.cancelvalidation) {
      dispatch(closeDrawerAction());
    } else {
      dispatch(closeDrawerAction());
    }
  };

  const isDeleteDeliverableDisable = () => {
    if (state.selectedAllDeliverables) {
      return true;
    }
    let returnFlag1 = true;
    let returnFlag2 = true;
    let returnFlag3 = true;

    if (userIsConsultant)
      returnFlag1 = !selectedDeliverables.every(
        (d) =>
          !d.mission_advancement_id &&
          !d.origin.includes('quote') &&
          !d.origin.includes('custom') &&
          !d.origin.includes('catalog') &&
          !d.label?.includes('added')
      );
    //  console.log(`returnFlag1 : ${returnFlag1}`);

    if (userIsBm || userIsTl || userIsTlPlus || userIsAdmin) {
      returnFlag2 = !selectedDeliverables.every(
        (d) =>
          !d.origin.includes('quote') &&
          !d.origin.includes('custom') &&
          !d.origin.includes('catalog') &&
          !d.label?.includes('added') &&
          (!d.mission_advancement_id ||
            (d.mission_advancement_id && d.mission_advancement_id > MissionAdvancementPossibilities.SUBMITTED))
      );
      //    console.log(`returnFlag2 : ${returnFlag2}`);
    }
    returnFlag3 = !selectedDeliverables.every(
      (d) =>
        !d.mission_advancement_id &&
        (d.origin.includes('custom') || (d.origin.includes('catalog') && d.label?.includes('added')))
    );
    //  console.log(`returnFlag3 : ${returnFlag3}`);

    if (!returnFlag1) return returnFlag1;
    if (!returnFlag2) return returnFlag2;
    return returnFlag3;
    /*     if (state.selectedItems.every((m) => !m.mission_advancement_id && !m.workunit_id)) return false;
    return true; */
  };

  const countDeletableDeliverable = () =>
    selectedDeliverables.filter((m) => {
      if (m.origin === 'quote') return false;
      if (m.origin.toLowerCase() === 'custom' || m.label?.toLowerCase() === 'added') {
        if (m.mission_advancement_id && m.mission_advancement_id >= MissionAdvancementPossibilities.SUBMITTED)
          return false;
      }
      if (m.mission_advancement_id && m.mission_advancement_id < MissionAdvancementPossibilities.RESERVE_TL)
        if (userIsAdmin || userIsTl || userIsBm || userIsTlPlus) return true;
      if (!m.mission_advancement_id) {
        if (userIsConsultant && m.consultant?.id === appState.user?.id) {
          return true;
        }
        if (userIsAdmin || userIsTl || userIsBm || userIsTlPlus) return true;
      }
      return false;
    }).length;

  const countSubmittableDeliverable = () => {
    let count = 0;
    if (userIsAdmin || userIsBm || userIsTl || userIsConsultant) {
      count = selectedDeliverables.filter(
        (m) => !m.mission_advancement_id || m.mission_advancement_id === MissionAdvancementPossibilities.REFUSED_TL
      ).length;
    }
    return count;
  };

  const countCancellableSubmittedDeliverables = () => {
    let count = 0;
    if (userIsAdmin || userIsBm || userIsTl || userIsTlPlus || userIsConsultant) {
      count = selectedDeliverables.filter(
        (m) => m.mission_advancement_id && m.mission_advancement_id === MissionAdvancementPossibilities.SUBMITTED
      ).length;
    }
    return count;
  };

  const countCancellableValidatedDeliverables = () => {
    let count = 0;
    if (userIsAdmin || userIsBm || userIsTl || userIsTlPlus) {
      count = selectedDeliverables.filter(
        (m) =>
          m.mission_advancement_id &&
          (m.mission_advancement_id === MissionAdvancementPossibilities.ACCEPTATION_TL ||
            m.mission_advancement_id === MissionAdvancementPossibilities.RESERVE_TL)
      ).length;
    }
    return count;
  };

  const countValidableDeliverables = () => {
    let count = 0;
    if (userIsAdmin || userIsBm || userIsTl) {
      count = selectedDeliverables.filter(
        (m) => m.mission_advancement_id && m.mission_advancement_id === MissionAdvancementPossibilities.SUBMITTED
      ).length;
    }
    return count;
  };

  const countClientAcceptanceRecords = () => {
    let count = 0;
    if (userIsAdmin || userIsBm || userIsTl || userIsTlPlus || userIsConsultant) {
      count = selectedDeliverables.filter(
        (m) => m.mission_advancement_id && m.mission_advancement_id > MissionAdvancementPossibilities.ACCEPTATION_TL
      ).length;
    }
    return count;
  };
  const canCancelSubmission = () => {
    if (state.selectedAllDeliverables) {
      return true;
    }

    if (userIsAdmin || userIsBm || userIsTl || userIsTlPlus || userIsConsultant) {
      if (countClientAcceptanceRecords() > 0) {
        return true;
      }

      if (userIsConsultant) {
        return selectedDeliverables.some(
          (m) => m.mission_advancement_id && m.mission_advancement_id > MissionAdvancementPossibilities.SUBMITTED
        );
      }
      return !selectedDeliverables.some(
        (m) => m.mission_advancement_id && m.mission_advancement_id === MissionAdvancementPossibilities.SUBMITTED
      );
    }
    return false;
  };

  const canCancelValidation = () => {
    if (state.selectedAllDeliverables) {
      return true;
    }

    if (userIsAdmin || userIsBm || userIsTl || userIsTlPlus) {
      if (countClientAcceptanceRecords() > 0) {
        return true;
      }
      return !selectedDeliverables.some(
        (m) =>
          m.mission_advancement_id &&
          m.mission_advancement_id >= MissionAdvancementPossibilities.RESERVE_TL &&
          m.mission_advancement_id <= MissionAdvancementPossibilities.ACCEPTATION_TL
      );
    }
    return false;
  };

  const isAcceptDisabled = () => {
    if (state.selectedAllDeliverables) {
      return true;
    }
    if (userIsCustomer || (!clientConnection && canManagerAccept)) {
      return !selectedDeliverables.every(
        (m) =>
          m.mission_advancement_id &&
          m.mission_advancement_id >= MissionAdvancementPossibilities.RESERVE_TL &&
          ![
            MissionAdvancementPossibilities.REFUSED_CLIENT,
            MissionAdvancementPossibilities.RESERVE_CLIENT,
            MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
          ].includes(m.mission_advancement_id)
      );
    }
    return true;
  };

  const canUserEvaluateDeliverables = () => {
    if (state.selectedAllDeliverables) {
      return true;
    }
    if (userIsCustomer) {
      return selectedDeliverables.every(
        (m) =>
          m.mission_advancement_id &&
          m.mission_advancement_id >= MissionAdvancementPossibilities.RESERVE_CLIENT &&
          (!m.rating || m.rating === '')
      );
    }
    return true;
  };

  const isValidateButtonDisabled = useCallback(() => {
    if (Object.values(quickActions).every((q) => !q)) return true;
    if (Object.values(quickActions).some((q) => q)) {
      if (quickActions.cancellation && (!cancelReason || !cancelReason.length)) return true;
      return false;
    }
    return true;
  }, [cancelReason, quickActions]);
  return (
    <>
      <Box sx={{ mb: 3 }}>
        {!userIsCustomer && (
          <Accordion disableGutters elevation={0} disabled={!quickActionStatusDisabled.submission}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="sect"
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: 1 }}>{t<string>('Deliverable_submission')}</Box>
                {quickActionsAffectation.submission !== 0 && (
                  <Chip
                    size="small"
                    color="primary"
                    label={quickActionsAffectation.submission}
                    sx={{ fontSize: '1rem', fontWeight: 500 }}
                  />
                )}
              </Box>
            </AccordionSummary>

            <FormGroup>
              <FormControlLabel
                control={<Checkbox {...cancellationLabel} checked={quickActions.submission} />}
                label={t<string>('Submit_selected_deliverables')}
                onChange={(e, checked) => setQuickActions({ ...quickActions, submission: checked })}
              />
            </FormGroup>
          </Accordion>
        )}

        {!userIsCustomer && !userIsConsultant && (
          <Accordion disableGutters elevation={0} disabled={!quickActionStatusDisabled.validation}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="sect"
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: 1 }}>{t<string>('Deliverables_validation')}</Box>
                {quickActionsAffectation.validation !== 0 && (
                  <Chip
                    size="small"
                    color="primary"
                    label={quickActionsAffectation.validation}
                    sx={{ fontSize: '1rem', fontWeight: 500 }}
                  />
                )}
              </Box>
            </AccordionSummary>

            <FormGroup>
              <FormControlLabel
                control={<Checkbox {...cancellationLabel} checked={quickActions.validation} />}
                label={t<string>('Validate_selected_deliverables')}
                onChange={(e, checked) => setQuickActions({ ...quickActions, validation: checked })}
              />
            </FormGroup>
          </Accordion>
        )}
        {(userIsCustomer || (!clientConnection && canManagerAccept)) && (
          <Accordion disableGutters elevation={0} disabled={quickActionStatusDisabled.acceptation}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="sect"
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {t<string>('Deliverables_acceptance')}
            </AccordionSummary>

            <FormGroup>
              <FormControlLabel
                control={<Checkbox {...cancellationLabel} checked={quickActions.acceptation} />}
                label={t<string>('Accept')}
                onChange={(e, checked) => setQuickActions({ ...quickActions, acceptation: checked })}
              />
            </FormGroup>
          </Accordion>
        )}
        {!userIsCustomer && (
          <Accordion disableGutters elevation={0} disabled={!quickActionStatusDisabled.cancellation}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="sect"
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: 1 }}>{t<string>('Cancellation')}</Box>
                {quickActionsAffectation.cancellation !== 0 && (
                  <Chip
                    size="small"
                    color="primary"
                    label={quickActionsAffectation.cancellation}
                    sx={{ fontSize: '1rem', fontWeight: 500 }}
                  />
                )}
              </Box>
            </AccordionSummary>

            <FormGroup>
              <FormControlLabel
                control={<Checkbox {...cancellationLabel} checked={quickActions.cancellation} />}
                label={t<string>('Cancel_selected_deliverables')}
                onChange={(e, checked) => setQuickActions({ ...quickActions, cancellation: checked })}
              />
            </FormGroup>
            {quickActions.cancellation && (
              <>
                <Typography>{t('Reason of cancellation')}</Typography>
                <TextField
                  id="cancel-reason-input"
                  name="cancel-reason"
                  onChange={(e) => setCancelReason(e.target.value)}
                  placeholder={t('Enter your text here')}
                  value={cancelReason ?? ''}
                  required
                  rows={4}
                  multiline
                  fullWidth
                  disabled={!!loadings.api}
                />
              </>
            )}
          </Accordion>
        )}
        {(userIsAdmin || userIsBm || userIsTl || userIsTlPlus || userIsConsultant) && (
          <Accordion disableGutters elevation={0} disabled={quickActionStatusDisabled.cancelsubmission}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="sect"
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: 1 }}>{t<string>('Cancel_submission')}</Box>
                {quickActionsAffectation.cancelsubmission !== 0 && (
                  <Chip
                    size="small"
                    color="primary"
                    label={quickActionsAffectation.cancelsubmission}
                    sx={{ fontSize: '1rem', fontWeight: 500 }}
                  />
                )}
              </Box>
            </AccordionSummary>

            <FormGroup>
              <FormControlLabel
                control={<Checkbox {...cancellationSubmissionLabel} checked={quickActions.cancelsubmission} />}
                label={t<string>('Cancel_submission_selected_deliverables')}
                onChange={(e, checked) => setQuickActions({ ...quickActions, cancelsubmission: checked })}
              />
            </FormGroup>
          </Accordion>
        )}
        {(userIsAdmin || userIsBm || userIsTl || userIsTlPlus) && (
          <Accordion disableGutters elevation={0} disabled={quickActionStatusDisabled.cancelvalidation}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="sect"
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: 1 }}>{t<string>('Cancel_validation')}</Box>
                {quickActionsAffectation.cancelvalidation !== 0 && (
                  <Chip
                    size="small"
                    color="primary"
                    label={quickActionsAffectation.cancelvalidation}
                    sx={{ fontSize: '1rem', fontWeight: 500 }}
                  />
                )}
              </Box>
            </AccordionSummary>

            <FormGroup>
              <FormControlLabel
                control={<Checkbox {...cancellationValidationLabel} checked={quickActions.cancelvalidation} />}
                label={t<string>('Cancel_validation_selected_deliverables')}
                onChange={(e, checked) => setQuickActions({ ...quickActions, cancelvalidation: checked })}
              />
            </FormGroup>
          </Accordion>
        )}
        {!userIsCustomer && (
          <Accordion disableGutters elevation={0} disabled={quickActionStatusDisabled.delete}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="sect"
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: 1 }}>{t<string>('Delete_deliverables')}</Box>
                {quickActionsAffectation.delete !== 0 && (
                  <Chip
                    size="small"
                    color="primary"
                    label={quickActionsAffectation.delete}
                    sx={{ fontSize: '1rem', fontWeight: 500 }}
                  />
                )}
              </Box>
            </AccordionSummary>

            <FormGroup>
              <FormControlLabel
                control={<Checkbox {...cancellationLabel} checked={quickActions.delete} />}
                label={t<string>('Delete_deliverables')}
                onChange={(e, checked) => setQuickActions({ ...quickActions, delete: checked })}
              />
            </FormGroup>
          </Accordion>
        )}
        {(userIsCustomer || (!clientConnection && canManagerRate)) &&
          (canDeliverablesBeEvaluated || quickActions.acceptation) &&
          !state.selectedAllDeliverables && (
            <Accordion disableGutters elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="sect"
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {t<string>('Deliverables assessment')}
              </AccordionSummary>

              <FormGroup>
                <FormControlLabel
                  control={<Checkbox {...cancellationLabel} checked={quickActions.eval === 'A'} />}
                  label={t<string>('A')}
                  translate="no"
                  onChange={(e, checked) => setQuickActions({ ...quickActions, eval: 'A' })}
                />
                <FormControlLabel
                  control={<Checkbox {...cancellationLabel} checked={quickActions.eval === 'B'} />}
                  label={t<string>('B')}
                  onChange={(e, checked) => setQuickActions({ ...quickActions, eval: 'B' })}
                />
                <FormControlLabel
                  control={<Checkbox {...cancellationLabel} checked={quickActions.eval === 'C'} />}
                  label={t<string>('C')}
                  onChange={(e, checked) => setQuickActions({ ...quickActions, eval: 'C' })}
                />
              </FormGroup>
            </Accordion>
          )}
      </Box>
      <LoadingButton
        loading={!!loadings.api}
        disabled={isValidateButtonDisabled()}
        onClick={(event) => submit(event)}
        variant="contained"
        color="success"
      >
        {t('Validate')}
      </LoadingButton>
    </>
  );
};

export default DeliverableActions;
